<template>
    <header class="main-header" :class="{'scrolled': scrollDown || scrollUp}">
        
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12" class="main-header__inner-wrap">
                    <div class="main-header__inner">
                        <div class="logo-wrap">
                            <router-link :to="{path: '/'}" class="logo">
                                <img src="@/assets/images/global-samsung-logo.svg" alt="Samsung" />
                            </router-link>
                        </div>
                        <div class="logo-wrap__inner">
                            <div class="hamburger-menu" @click="showMenu = true"></div> 
                            <div class="submenu" :class="{'show': showMenu}">
                                <div class="navbar-button-container">
                                    <button type="button" class="hamburger-menu-close" @click="showMenu = false"></button>                                    
                                    <!--<a class="navbar-button" href="#section1" v-scroll-to="'#section1'" @click="showMenu = false">Betelt a pohár!</a>-->
                                    <router-link class="navbar-button mx-lg-5 mx-0" :to="{path: '/'}" v-scroll-to="{el: '#more', offset: -200}" @click="showMenu = false">Tudj meg többet a cyberbullyingról!</router-link>
                                    <!--<a class="navbar-button" href="#events" v-scroll-to="{el: '#events', offset: -200}" @click="showMenu = false">Okos ünnepek</a>-->
                                </div>
                            </div>
                            <a href="https://www.samsung.com/hu/smartphones/" target="_blank" class="btn btn--black">Okostelefonok</a>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>

    </header>
</template>

<script>
export default {
    data(){
        return{
            lastScrollTop:0,
            scrollDown:false,
            scrollUp:false,
            showMenu:false            
        }
    },
    methods:{
        scrollDirection(){
            let _this = this

            var st = window.pageYOffset || document.documentElement.scrollTop;

            if (st > this.lastScrollTop){
                // downscroll
                // console.log('scroll down')
                _this.scrollDown = true,
                _this.scrollUp = false
            } else {
                // upscroll
                // console.log('scroll up')
                _this.scrollDown = false
                _this.scrollUp = true
            }

            this.lastScrollTop = st <= 0 ? 0 : st;

            if ( this.lastScrollTop === 0){
                _this.scrollDown = false
                _this.scrollUp = false
            }

        },
    },
    created(){
        let _this = this

        window.addEventListener("scroll", function(){
            _this.scrollDirection()
        }, false);
    },
    destroyed(){
        let _this = this
        window.removeEventListener('scroll', _this.scrollDirection());
    }
}
</script>